import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/companystorecredit/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/companystorecredit/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/companystorecredit/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/companystorecredit/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/companystorecredit/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportCompanyStoreCredit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
              url: '/api/exportexcelfile/exportCompanyStoreCredit',
              method:'get',
              params: params,
              responseType: 'blob',
            }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
