import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function companystorecreditUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'csr_id', label: 'ID', sortable: true },
    { key: 'company_name', label: '终端客户名称', sortable: true },
    { key: 'store_name', label: '团队名称', sortable: true },
    { key: 'year', label: '年份', sortable: true },
    { key: 'month', label: '月份', sortable: true },
    { key: 'custom_level', label: '客户评级', sortable: true },
    { key: 'credit_amount', label: '授信额度', sortable: true },
    { key: 'credit_amount_used', label: '已用授信额度', sortable: true },
    { key: 'credit_amount_remain', label: '剩余授信额度', sortable: true },
    { key: 'credit_amount_temp', label: '临时额度', sortable: true },
    { key: 'credit_amount_used_temp', label: '已用临时额度', sortable: true },
    { key: 'credit_amount_remain_temp', label: '剩余临时额度', sortable: true },
    { key: 'can_use_amount', label: '实际可用额度', sortable: true },
    { key: 'deposit_money', label: '押单金额', sortable: true },

  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('csr_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('companystorecredit/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        company_id: condition.value.company_id,
        store_id: condition.value.store_id,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
